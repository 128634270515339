import React from 'react'
import NavBar from './NavBar'
import Home from './Home'
import FooterEntrada from './FooterEntrada'
import Cards from './Cards'

const HomeRender = () => {
    return (
  <div className="hero-foot">
  <NavBar />
  <Home />
  <div className="column">
  <p className="bd-notification is-primary"></p>
  </div>
  <div className="column">
    <p className="bd-notification is-primary"></p>
  </div>
  <div className="column">
    <p className="bd-notification is-primary"></p>
  </div>

  <div className="columns">
  <div className="column"></div>
  <div className="column is-half">
  <Cards />
  </div>
  <div className="column"></div>
  </div>
<div className="columns">
  <div className="column is-full">  <FooterEntrada /></div>
</div>
</div>


        )

        
    }




export default HomeRender
