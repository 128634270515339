import React from 'react'
import { Link } from 'react-router-dom'

const Cards = () => {
    return (
<div className="card">
  <header className="card-header">
    <p className="card-header-title">
    Inscribete para la Temporada
    </p>

      <span className="icon">
        <i className="fas fa-angle-down" aria-hidden="true"></i>
      </span>

  </header>
  <div className="card-content">
  <div className="content ">
   ¡Estamos listos para comenzar un nuevo proceso de postulación para la Temporada en Ranco! 
    </div>
  </div>
  <footer className="card-footer">
  <Link to='/postulante' className="card-footer-item">Pincha Aquí</Link>
  </footer>
</div>

    )
}

export default Cards
