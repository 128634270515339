import React, {Component} from 'react'
import './components.css'

class Footer extends Component{
    render(){
        return (
<div className="">
<footer className="footer">
  <div className="content has-text-centered">
    <div>
      <strong>Ranco</strong> by <span className='spanletter'>Ranco Desarrollos &copy;</span> . Para más información enviar email a &nbsp;
      <span className='spanletter'>inforanco@ranco.cl</span>
    </div>
  </div>
</footer>
</div>
)}
}

export default Footer
