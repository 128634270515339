import React from 'react'
import NavBar from './NavBar'
import FormularioPostulantes from './FormularioPostulantes'
import Footer from './Footer'

function Formulario() {
    return (
    <div className='rows'>
    <NavBar />
    <div className = 'row is-full'>
    <FormularioPostulantes />
    </div>
    <div className = 'row is-full'>
    <Footer />
    </div>
    </div>
    )


}

export default Formulario
