import React from 'react'

function Home() {
    return (
        <div className="homeback">
 
        </div>
    )
    
}

export default Home
