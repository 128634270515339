import React, { Component, useState } from "react";
import swal from "sweetalert";
import "./components.css";
import { rutValidate, rutFormat } from "rut-helpers";
import moment from "moment";



//Metodos,funciones, variables
const capitalize_Words = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const initialState = {
  id: "",
  planta: "",
  rut: "",
  nombres: "",
  apellidos: "",
  fecha_nacimiento: "",
  genero: "",
  nacionalidad: "",
  // 'direccion': '',
  comuna: "",
  estado_civil: "",
  email: "",
  telefono: "",
  tipo_trabajador: "",
  disponibilidad: "",
  tipo_inscripcion: "",
  labor: "",
  otras_experiencias: "",
  email_validado: 0,
  curriculum: null,
  temporada: "",
  situacion_migratoria: "",
  telefono_emergencia: "",
  nivel_educacional: "",
  talla_ropa: "",
  numero_calzado: "",
  calle: "",
  numero: "",
  villa: "",
  nombre_emergencia: "",
  inclusion:"",
};
const initialState2 = {
  plantaError: "",
  rutError: "",
  nombresError: "",
  apellidosError: "",
  fecha_nacimientoError: "",
  generoError: "",
  nacionalidadError: "",
  // 'direccionError': '',
  comunaError: "",
  estado_civilError: "",
  emailError: "",
  telefonoError: "",
  tipo_trabajadorError: "",
  disponibilidadError: "",
  tipo_inscripcionError: "",
  laborError: "",
  telefono_emergenciaError: "",
  nivel_educacionalError: "",
  talla_ropaError: "",
  numero_calzadoError: "",
  situacion_migratoriaError: "",
  calleError: "",
  numeroError: "",
  villaError: "",
  nombre_emergenciaError: "",
  inclusionError: "",
};

//Empieza clase
class FormularioPostulanteNuevo extends Component {

  state = {
    postulante: initialState,
    initialState2,
    comuna: [],
  };
  
  handleChangePlanta(target) {

    const planta = target

    const comunas = [...this.state.comuna];

    if (planta == 'Planta Rancagua') {
      comunas.splice(0, comunas.length, 'Rancagua', 'Codegua', 'Coinco', 'Coltauco', 'Doñihue', 'Graneros', 'Las Cabras.', 
                                        'Machalí', 'Malloa', 'Mostazal', 'Olivar', 'Peumo', 'Pichidegua', 'Quinta de Tilcoco',
                                        'Rengo','Requínoa','San Vicente de Tagua Tagua');
      this.state.comuna = comunas;
      this.state.postulante.comuna = '';

    } else if (planta == 'Planta Chimbarongo') {
      comunas.splice(0, comunas.length, 'Chimbarongo', 'San Fernando', 'Nancagua', 'Placilla', 'Chépica', 'Santa Cruz', 
                                        'Lolol', 'Pumanque', 'Palmilla', 'Peralillo', 'Pichidegua', 'San Vicente Tagua Tagua', 
                                        'Malloa', 'Quinta de Tilcoco', 'Otras');
      this.state.comuna = comunas;
      this.state.postulante.comuna = '';

    } else if (planta == 'Planta La Unión') {
      comunas.splice(0, comunas.length, 'La Unión', 'Río Bueno');
      this.state.comuna = comunas;
      this.state.postulante.comuna = '';
    } else{
      comunas.splice(0, comunas.length, '');
      this.state.comuna = comunas;
      this.state.postulante.comuna = '';
    }
    this.forceUpdate();
  }

  componentDidMount() {
    this.handleRut = this.handleRut.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.alertaSucces = this.alertaSucces.bind(this);
    this.alertaError = this.alertaError.bind(this);
    this.handleMask = this.handleMask.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handlerEdad = this.handlerEdad.bind(this);
  }

  alertaSucces() {
    //swal('IMPORTANTE', `Para finalizar correctamente su postulación confírmela en el correo que le hemos mandado a ${this.state.postulante.email}`, "info");
    swal(
      "IMPORTANTE",
      `Has postulado exitósamente a Ranco.

                        Puedes consultar sobre tu proceso de postulación a los siguientes datos de contacto

                        Correo : reclutamiento@ranco.cl
                        WhatsApp : + 56 9 8293 1129 ó + 56 9 4467 1575`,
      "success"
    );
  }
  alertaSalida() {
    swal("Gracias por postular a Ranco !", "Seras Redireccionado", "info");
  }
  alertaError() {
    swal("Algo salio mal", "Tendras que ingresar nuevamente", "warning");
  }
  validateForm = () => {
    let plantaError = "";
    let rutError = "";
    let nombresError = "";
    let apellidosError = "";
    let fecha_nacimientoError = "";
    let generoError = "";
    let nacionalidadError = "";
    // let direccionError = "";
    let comunaError = "";
    let estado_civilError = "";
    let emailError = "";
    let telefonoError = "";
    let tipo_trabajadorError = "";
    let disponibilidadError = "";
    let tipo_inscripcionError = "";
    let laborError = "";
    let telefono_emergenciaError = "";
    let nivel_educacionalError = "";
    let talla_ropaError = "";
    let numero_calzadoError = "";
    let situacion_migratoriaError = "";
    let calleError = "";
    let numeroError = "";
    let villaError = "";
    let nombre_emergenciaError = "";
    let inclusionError = "";
    
    console.log('validate', this.state.postulante);
    //Planta
    if (!this.state.postulante.planta) {
      plantaError = "Debe seleccionar una planta, favor de seleccionar.";
    }

    //Rut
    if (!this.state.postulante.rut) {
      rutError = "Casilla en blanco, favor de rellenar.";
    }

    //Nombres
    if (!this.state.postulante.nombres) {
      nombresError = "Casilla en blanco, favor de rellenar.";
    }

    //Apellidos
    if (!this.state.postulante.apellidos) {
      apellidosError = "Casilla en blanco, favor de rellenar.";
    }

    //Fecha de nacimiento
    if (!this.state.postulante.fecha_nacimiento) {
      fecha_nacimientoError = "Casilla en blanco, favor de rellenar.";
    }
    //Genero
    if (!this.state.postulante.genero) {
      generoError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.genero == "Seleccione") {
        const genero = "";
        generoError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Nacionalidad
    if (!this.state.postulante.nacionalidad) {
      nacionalidadError = "Casilla en blanco, favor de rellenar..";
    } else {
      if (this.state.postulante.nacionalidad == "Seleccione") {
        nacionalidadError = "Casilla en blanco, favor de rellenar...";
      }
      if (
        this.state.postulante.nacionalidad !== "Chile" &&
        !this.state.postulante.situacion_migratoria
      ) {
        situacion_migratoriaError =
          "Si es extranjero, debe definir su situación migratoria.";
      }
    }

    // //Direccion
    // if (!this.state.postulante.direccion){
    // direccionError = "Casilla en blanco, favor de rellenar.";
    // }
    //Comuna
    if (!this.state.postulante.comuna) {
      comunaError = "Casilla en blanco, favor de rellenar.";
    }
    //Estado Civil
    if (!this.state.postulante.estado_civil) {
      estado_civilError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.estado_civil == "Seleccione") {
        const estado_civil = "";
        estado_civilError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Email
    if (!this.state.postulante.email) {
      emailError = "Casilla en blanco, favor de rellenar.";
    }
    if (typeof this.state.postulante.email !== "undefined") {
      let lastAtPos = this.state.postulante.email.lastIndexOf("@");
      let lastDotPos = this.state.postulante.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.postulante.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.postulante.email.length - lastDotPos > 2
        )
      ) {
        emailError =
          "Email invalido, favor de ingresar un email valido. Ejemplo: luis@gmail.com)";
      }
    }
    //Telefono
    if (!this.state.postulante.telefono) {
      telefonoError = "Casilla en blanco, favor de rellenar.";
    }
    if (typeof this.state.postulante.telefono !== "undefined") {
      const caracteres2 = this.state.postulante.telefono.length;
      if (caracteres2 <= 8) {
        telefonoError = "El telefono debe contener 9 digitos.";
      }
    }

    //Telefono Emergencia
    if (!this.state.postulante.telefono_emergencia) {
      telefono_emergenciaError = "Casilla en blanco, favor de rellenar.";
    }
    if (typeof this.state.postulante.telefono_emergencia !== "undefined") {
      const caracteres2 = this.state.postulante.telefono_emergencia.length;
      if (caracteres2 <= 8) {
        telefono_emergenciaError = "El telefono debe contener 9 digitos.";
      } else {
        if (
          this.state.postulante.telefono ===
          this.state.postulante.telefono_emergencia
        ) {
          telefono_emergenciaError =
            "El telefono de emergencia no puede ser igual que el telefono. Favor ingresar uno diferente.";
        }
      }
    }

    //Tipo Trabajador
    if (!this.state.postulante.tipo_trabajador) {
      tipo_trabajadorError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.tipo_trabajador == "Seleccione") {
        const tipo_trabajador = "";
        tipo_trabajadorError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Disponibilidad
    if (!this.state.postulante.disponibilidad) {
      disponibilidadError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.disponibilidad == "Seleccione") {
        const disponibilidad = "";
        disponibilidadError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Inscripcion
    if (!this.state.postulante.tipo_inscripcion) {
      tipo_inscripcionError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.tipo_inscripcion == "Seleccione") {
        const tipo_inscripcion = "";
        tipo_inscripcionError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Labor
    if (!this.state.postulante.labor) {
      laborError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.labor == "Seleccione") {
        const labor = "";
        laborError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Nivel Educacional
    if (!this.state.postulante.nivel_educacional) {
      nivel_educacionalError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.nivel_educacional == "Seleccione") {
        const nivel_educacional = "";
        nivel_educacionalError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Talla Ropa
    if (!this.state.postulante.talla_ropa) {
      talla_ropaError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.talla_ropa == "Seleccione") {
        const talla_ropa = "";
        talla_ropaError = "Casilla en blanco, favor de rellenar.";
      }
    }

    //Numero de calzado
    if (!this.state.postulante.numero_calzado) {
      numero_calzadoError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.numero_calzado == "Seleccione") {
        const numero_calzado = "";
        numero_calzadoError = "Casilla en blanco, favor de rellenar.";
      }
    }
    //Calle
    if (!this.state.postulante.calle) {
      calleError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.calle == "Seleccione") {
        const calle = "";
        calleError = "Casilla en blanco, favor de rellenar.";
      }
    }
    //Numero
    if (!this.state.postulante.numero) {
      numeroError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.numero == "Seleccione") {
        const numero = "";
        numeroError = "Casilla en blanco, favor de rellenar.";
      }
    }
    //Villa
    if (!this.state.postulante.villa) {
      villaError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.villa == "Seleccione") {
        const villa = "";
        villaError = "Casilla en blanco, favor de rellenar.";
      }
    }
    //Nombre Emergencia
    if (!this.state.postulante.nombre_emergencia) {
      nombre_emergenciaError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.nombre_emergencia == "Seleccione") {
        nombre_emergenciaError = "Casilla en blanco, favor de rellenar.";
      }
    }
    
    //Inclusión
    if (!this.state.postulante.inclusion) {
      inclusionError = "Casilla en blanco, favor de rellenar.";
    } else {
      if (this.state.postulante.inclusion == "Seleccione") {
        const inclusion = "";
        inclusionError = "Casilla en blanco, favor de rellenar.";
      }
    }

    if (
      plantaError ||
      laborError ||
      tipo_inscripcionError ||
      disponibilidadError ||
      tipo_trabajadorError ||
      estado_civilError ||
      generoError ||
      comunaError ||
      emailError ||
      rutError ||
      nombresError ||
      apellidosError ||
      telefonoError ||
      telefono_emergenciaError ||
      fecha_nacimientoError ||
      nacionalidadError ||
      nivel_educacionalError ||
      talla_ropaError ||
      numero_calzadoError ||
      situacion_migratoriaError ||
      calleError ||
      numeroError ||
      villaError ||
      nombre_emergenciaError ||
      inclusionError
    ) {
      this.setState({
        plantaError,
        laborError,
        tipo_inscripcionError,
        disponibilidadError,
        tipo_trabajadorError,
        estado_civilError,
        generoError,
        comunaError,
        emailError,
        nombresError,
        apellidosError,
        telefonoError,
        telefono_emergenciaError,
        fecha_nacimientoError,
        nacionalidadError,
        rutError,
        nivel_educacionalError,
        talla_ropaError,
        numero_calzadoError,
        situacion_migratoriaError,
        calleError,
        numeroError,
        villaError,
        nombre_emergenciaError,
        inclusionError,
      });

      const mensaje = ``;
      swal({
        title: "Debe ingresar los datos obligatorios que están destacados con color rojo",
        html: mensaje,
        icon: "info"
      });
      return false;

    }
    this.setState({
      plantaError: "",
      rutError: "",
      nombresError: "",
      apellidosError: "",
      fecha_nacimientoError: "",
      generoError: "",
      nacionalidadError: "",
      comunaError: "",
      estado_civilError: "",
      emailError: "",
      telefonoError: "",
      telefono_emergenciaError: "",
      tipo_trabajadorError: "",
      disponibilidadError: "",
      tipo_inscripcionError: "",
      laborError: "",
      nivel_educacionalError: "",
      talla_ropaError: "",
      numero_calzadoError: "",
      situacion_migratoriaError: "",
      calleError: "",
      numeroError: "",
      villaError: "",
      nombre_emergenciaError: "",
      inclusionError: "",
    });
    return true;
  };

  handleFormReset = () => {
    const reset = {
      id: "",
      planta: "",
      rut: "",
      nombres: "",
      apellidos: "",
      fecha_nacimiento: "",
      genero: "",
      nacionalidad: "",
      comuna: "",
      estado_civil: "",
      email: "",
      telefono: "",
      telefono_emergencia: "",
      tipo_trabajador: "",
      disponibilidad: "",
      tipo_inscripcion: "",
      labor: "",
      otras_experiencias: "",
      email_validado: 0,
      curriculum: null,
      temporada: "",
      situacion_migratoria: "",
      nivel_educacional: "",
      talla_ropa: "",
      numero_calzado: "",
      calle: "",
      numero: "",
      villa: "",
      nombre_emergencia: "",
      inclusion: "",
    };
    this.setState({ postulante: reset });
  };
  handleFormResetError = () => {
    this.setState({
      plantaError: "",
      rutError: "",
      nombresError: "",
      apellidosError: "",
      fecha_nacimientoError: "",
      generoError: "",
      nacionalidadError: "",
      comunaError: "",
      estado_civilError: "",
      emailError: "",
      telefonoError: "",
      telefono_emergenciaError: "",
      tipo_trabajadorError: "",
      disponibilidadError: "",
      tipo_inscripcionError: "",
      laborError: "",
      nivel_educacionalError: "",
      talla_ropaError: "",
      numero_calzadoError: "",
      situacion_migratoriaError: "",
      calleError: "",
      numeroError: "",
      villaError: "",
      nombre_emergencia: "",
      inclusion:"",
    });
  };

  esExtranjero = (e) => {
    const strNacionalidad = this.state.postulante.nacionalidad;
    if (strNacionalidad === "Chile" || strNacionalidad === "Seleccione") {
      document.getElementById("situacion_migratoria").disabled = true;
    } else {
      document.getElementById("situacion_migratoria").disabled = false;
    }
  };

  handleMask = (e) => {
    const { postulante } = this.state;
    const rut_v = rutFormat(e.target.value);
    const valida = rutValidate(rut_v);
    if (valida) {
      const formato = rutFormat(rut_v);

      
      this.setState({ postulante: { ...postulante, rut: formato } });
      if (
        rut_v.length === 8 ||
        rut_v.length === 9 ||
        rut_v.length === 11 ||
        rut_v.length === 12
      ) {
        this.handleRut(formato);
      }
    }
    if (!valida) {
      const formato = rutFormat(rut_v);
      this.setState({ postulante: { ...postulante, rut: formato } });
      if (
        rut_v.length === 5 ||
        rut_v.length === 6 ||
        rut_v.length === 7 ||
        rut_v.length === 8 ||
        rut_v.length === 9 ||
        rut_v.length === 11 ||
        rut_v.length === 12
      ) {
        swal(
          "Rut invalido",
          "Tendras que ingresar el rut nuevamente",
          "warning"
        );
        this.handleFormReset();
      }
    }
  };

  handleDate = (e) => {
    this.setState({
      postulante: { fecha_nacimiento: e.target.value },
    });
  };
  handleRut = async (rut) => {
    const rut_verificar = rut;
    const yaPostulo = this.handleRutYEmail(rut_verificar,process.env.REACT_APP_TEMPORADA);
    const validarPostulacion = await yaPostulo.valueOf();
    if (validarPostulacion) {
      swal(
        "Ya estas inscrito para la temporada!",
        `No hace falta que postules nuevamente
                                                     
                                                     Si deseas comunicarte con nosotros, puedes hacerlo a los siguientes contactos
                                                     
                                                     Correo : reclutamiento@ranco.cl
                                                     WhatsApp : + 56 9 8293 1129 ó + 56 9 4467 1575

                                                     Gracias por postular a Ranco.`,
        "success"
      );
      this.handleFormReset();
      this.handleFormResetError();
    }
    if (!validarPostulacion) {
    }
  };

  handleRutYEmail = async (rut,temporada) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/postulante_rut?rut=${rut}&temporada=${temporada}`,
        {
          method: "GET",
          
          headers: new Headers({
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            "Content-Type": "application/json",
          }),
        }
      );
      const json = await response.json();
      if (json.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  addPostulante = async (_) => {
    const { postulante } = this.state;
    const {
      planta,
      rut,
      nombres,
      apellidos,
      fecha_nacimiento,
      genero,
      nacionalidad,
      comuna,
      estado_civil,
      email,
      telefono,
      telefono_emergencia,
      tipo_trabajador,
      disponibilidad,
      tipo_inscripcion,
      labor,
      otras_experiencias,
      curriculum,
      situacion_migratoria,
      nivel_educacional,
      talla_ropa,
      numero_calzado,
      calle,
      numero,
      villa,
      nombre_emergencia,
      inclusion,
    } = postulante;
    const nuevoTelefono = telefono ? `56${telefono}` : null;
    const nuevoTelefono_emergencia = telefono_emergencia
      ? `56${telefono_emergencia}`
      : null;
    const nuevoNombres = capitalize_Words(nombres);
    const nuevoApellidos = capitalize_Words(apellidos);
    const nuevaNacionalidad = capitalize_Words(nacionalidad);
    const nuevaDireccion = capitalize_Words(
      calle.concat(" ", "#", numero, " ,", villa)
    );
    // const nuevaDireccion = capitalize_Words(direccion);
    const otras_experiencias2 =
      otras_experiencias === undefined
        ? "No escribio otras experiencias"
        : otras_experiencias;
    const email_validado = 1;
    const temporada = process.env.REACT_APP_TEMPORADA;
    const situacion_migratoria2 =
      situacion_migratoria === undefined ? "" : situacion_migratoria;
    const cv1 = curriculum ? curriculum.name.toString() : null;
    const cv2 = curriculum
      ? JSON.stringify(cv1.split(".").slice(1))
          .replace('["', "")
          .replace('"]', "")
      : null;
    const ruta = curriculum
      ? `C:/Users/desarrollo/Exportadora Rancagua S.A/Planta - Producción - Cv_Postulantes/Curriculum_${rut}.${cv2}`
      : "No adjunto cv ";
    const fecha_postulacion = moment().format("YYYY-MM-DD HH:mm:ss");
    try {
      await fetch(
        `${process.env.REACT_APP_URL}/postulante`,

        {
          method: "POST",
          
          headers: new Headers({
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            planta: planta,
            fecha_postulacion: fecha_postulacion,
            rut: rut,
            nombres: removeAccents(nuevoNombres),
            apellidos: removeAccents(nuevoApellidos),
            fecha_nacimiento: fecha_nacimiento,
            genero: genero,
            nacionalidad: nuevaNacionalidad,
            direccion: nuevaDireccion,
            comuna: comuna,
            estado_civil: estado_civil,
            email: email,
            telefono: nuevoTelefono,
            telefono_emergencia: nuevoTelefono_emergencia,
            tipo_trabajador: tipo_trabajador,
            disponibilidad: disponibilidad,
            tipo_inscripcion: tipo_inscripcion,
            labor: labor,
            otras_experiencias: otras_experiencias2,
            email_validado: email_validado,
            ruta: ruta,
            temporada: temporada,
            situacion_migratoria: situacion_migratoria2,
            nivel_educacional: nivel_educacional,
            talla_ropa: talla_ropa,
            numero_calzado: numero_calzado,
            calle: calle,
            numero: numero,
            villa: villa,
            nombre_emergencia: nombre_emergencia,
            inclusion: inclusion,
          }),
        }
      );
      if (curriculum) {
        this.handlerCV(curriculum, rut, email);
      }

      this.sendEmail(
        planta,
        fecha_postulacion,
        rut,
        nuevoNombres,
        nuevoApellidos,
        fecha_nacimiento,
        genero,
        nuevaNacionalidad,
        nuevaDireccion,
        comuna,
        estado_civil,
        email,
        nuevoTelefono,
        nuevoTelefono_emergencia,
        tipo_trabajador,
        disponibilidad,
        tipo_inscripcion,
        labor,
        otras_experiencias2,
        situacion_migratoria2,
        nivel_educacional,
        talla_ropa,
        numero_calzado,
        inclusion,
      );
    } catch (err) {
      console.error(err);
    }
  };
  sendEmail = async (
    planta,
    fecha_postulacion,
    rut,
    nuevoNombres,
    nuevoApellidos,
    fecha_nacimiento,
    genero,
    nuevaNacionalidad,
    nuevaDireccion,
    comuna,
    estado_civil,
    email,
    nuevoTelefono,
    nuevoTelefono_emergencia,
    tipo_trabajador,
    disponibilidad,
    tipo_inscripcion,
    labor,
    otras_experiencias2,
    situacion_migratoria2,
    nivel_educacional,
    talla_ropa,
    numero_calzado,
    temporada,
    inclusion
  ) => {
    try {
      await fetch(
        `${process.env.REACT_APP_URL}/send`,

        {
          method: "POST",
          
          headers: new Headers({
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            planta: planta,
            fecha_postulacion: fecha_postulacion,
            rut: rut,
            nombres: nuevoNombres,
            apellidos: nuevoApellidos,
            fecha_nacimiento: fecha_nacimiento,
            genero: genero,
            nacionalidad: nuevaNacionalidad,
            direccion: nuevaDireccion,
            comuna: comuna,
            estado_civil: estado_civil,
            email: email,
            telefono: nuevoTelefono,
            telefono_emergencia: nuevoTelefono_emergencia,
            tipo_trabajador: tipo_trabajador,
            disponibilidad: disponibilidad,
            tipo_inscripcion: tipo_inscripcion,
            labor: labor,
            otras_experiencias: otras_experiencias2,
            situacion_migratoria: situacion_migratoria2,
            nivel_educacional: nivel_educacional,
            talla_ropa: talla_ropa,
            numero_calzado: numero_calzado,
            temporada: temporada,
            inclusion: inclusion,
          }),
        }
      );
    } catch (err) {
      console.error(err);
    }
  };
  
  

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validateForm();
    if (isValid) {
      this.addPostulante();
      this.handleFormResetError();
      this.handleFormReset();
      this.alertaSucces();
      setTimeout(() => {
        window.location.href = "https://postulaciones.ranco.cl";
        //window.location.href = "http://localhost:3001"
      }, 15000);
    }
  };

  handlerEdad = (fecha_nacimiento) => {
    const hoy = new Date().getFullYear();
    const fecha_anio = new Date(fecha_nacimiento).getFullYear();
    const consulta = hoy - fecha_anio;
    if (consulta <= 15) {
      swal(
        "La fecha de nacimiento indicada corresponde a un menor de edad. Revise el año de nacimiento",
        "",
        "warning"
      );
      this.setState({ postulante: { fecha_nacimiento: "" } });
    }
  };

  handlerCV = async (cv, rut, email) => {
    const data = new FormData();
    data.append("file", cv);
    try {
      await fetch(
        `${process.env.REACT_APP_URL}/uploadCV?rut=${rut}&email=${email}`,

        {
          method: "POST",
          
          headers: new Headers({
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          }),
          body: data,
        }
      );
    } catch (err) {
      console.error(err, "error al enviar up");
    }
  };

  render() {
    const { postulante } = this.state;
    return (
      <div className="container2">
        <br />
        <div className="container">
          <form
            onSubmit={(this.validateForm, this.handleSubmit)}
            onReset={this.handleFormReset}
            className="formulariop is-vcentered"
          >
            {/*
    <div className="field is-horizontal">
      <div className="field-body">
      <div className="field is-narrow">
        <label className="label"><div ClassName="content">Los campos marcados (*) son obligtorios</div></label>
      </div>
      </div>
    </div>
    */}
            
            <div className="field is-horizontal has-text-left">
              <div className="field-body">
                <div className="field is-narrow">
                <label className="label">¿A qué planta desea postular?</label>
                  {this.state.plantaError ? (
                    <div className="control has-icons-left select is-danger">
                      <select
                        onChange={(e) => {
                          this.handleChangePlanta(e.target.value);
                          this.setState({
                            postulante: { ...postulante, planta: e.target.value === "Seleccione"? "": e.target.value},
                          })
                        }}
                        value={this.state.postulante.planta}
                        className="selecetext select is-danger is-normal"
                      >
                        <option>Seleccione</option>
                        <option>Planta Rancagua</option>
                        <option>Planta Chimbarongo</option>
                        <option>Planta La Unión</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-home"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary">
                      <select
                        onChange={(e) => {
                          this.handleChangePlanta(e.target.value);
                          this.setState({
                            postulante: { ...postulante, planta: e.target.value === "Seleccione"? "": e.target.value},
                          })
                        }}
                        value={this.state.postulante.planta}
                        className="selecetext select is-primary is-normal"
                      >
                        <option>Seleccione</option>
                        <option>Planta Rancagua</option>
                        <option>Planta Chimbarongo</option>
                        <option>Planta La Unión</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-home"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">{this.state.plantaError}</div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Rut</label>
                  <div className="control has-icons-left ">
                    {this.state.rutError ? (
                      <input
                        onBlur={this.handleMask}
                        maxLength={12}
                        onChange={(e) =>
                          this.setState({
                            postulante: { ...postulante, rut: e.target.value },
                          })
                        }
                        className="input is-danger is-square"
                        type="text"
                        placeholder="12.345.678-9"
                        value={this.state.postulante.rut}
                      />
                    ) : (
                      <input
                        onBlur={this.handleMask}
                        maxLength={12}
                        onChange={(e) =>
                          this.setState({
                            postulante: { ...postulante, rut: e.target.value },
                          })
                        }
                        className="input is-primary is-square"
                        type="text"
                        placeholder="12.345.678-9"
                        value={this.state.postulante.rut}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-address-card"></i>
                    </span>
                    <div className="help is-danger">{this.state.rutError}</div>
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Nombres</label>
                  <div className="control has-icons-left ">
                    {this.state.nombresError ? (
                      <input
                        value={this.state.postulante.nombres}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              nombres: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="text"
                        placeholder="Luis Alejandro"
                        style={{ textTransform: "uppercase" }}
                      />
                    ) : (
                      <input
                        value={this.state.postulante.nombres}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              nombres: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square"
                        type="text"
                        placeholder="Luis Alejandro"
                        style={{ textTransform: "uppercase" }}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <div className="help is-danger">
                    {this.state.nombresError}
                  </div>
                </div>
              </div>

              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Apellidos</label>
                  <div className="control has-icons-left ">
                    {this.state.apellidosError ? (
                      <input
                        value={this.state.postulante.apellidos}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              apellidos: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="type"
                        placeholder="Gonzalez Villanueva"
                        style={{ textTransform: "uppercase" }}
                      />
                    ) : (
                      <input
                        value={this.state.postulante.apellidos}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              apellidos: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square"
                        type="type"
                        placeholder="Gonzalez Villanueva"
                        style={{ textTransform: "uppercase" }}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <div className="help is-danger">
                    {this.state.apellidosError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Fecha de Nacimiento</label>
                  <div className="control  has-icons-right">
                    
                  {this.state.fecha_nacimientoError ? (
                      <input
                        onBlur={() => {
                          this.handlerEdad(
                            this.state.postulante.fecha_nacimiento
                          );
                        }}
                        max={moment().format("YYYY-DD-DD")}
                        value={this.state.postulante.fecha_nacimiento}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              fecha_nacimiento: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="date"
                      />
                    ) : (
                      <input
                        value={this.state.postulante.fecha_nacimiento}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              fecha_nacimiento: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square "
                        type="date"
                        max={moment().format("YYYY-DD-DD")}
                        onBlur={() => {
                          this.handlerEdad(
                            this.state.postulante.fecha_nacimiento
                          );
                        }}
                      />
                    )}
                    <div className="help is-danger">
                      {this.state.fecha_nacimientoError}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Calle/Pasaje</label>
                  <div className="control has-icons-left ">
                    {this.state.calleError ? (
                      <input
                        value={this.state.postulante.calle}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              calle: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="text"
                        placeholder="Av. Republica de Chile"
                        style={{ textTransform: "uppercase" }}
                      />
                    ) : (
                      <input
                        value={this.state.postulante.calle}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              calle: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square"
                        type="text"
                        placeholder="Av. Republica de Chile"
                        style={{ textTransform: "uppercase" }}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-road"></i>
                    </span>
                  </div>
                  <div className="help is-danger">{this.state.calleError}</div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">N°</label>
                  <div className="control has-icons-left ">
                    {this.state.numeroError ? (
                      <input
                        value={this.state.postulante.numero}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              numero: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="number"
                        placeholder="789"
                        style={{ textTransform: "uppercase" }}
                      />
                    ) : (
                      <input
                        value={this.state.postulante.numero}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              numero: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square"
                        type="number"
                        placeholder="789"
                        style={{ textTransform: "uppercase" }}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-hashtag"></i>
                    </span>
                  </div>
                  <div className="help is-danger">{this.state.numeroError}</div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Villa/Población</label>
                  <div className="control has-icons-left ">
                    {this.state.villaError ? (
                      <input
                        value={this.state.postulante.villa}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              villa: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="text"
                        placeholder="Los Alpes"
                        style={{ textTransform: "uppercase" }}
                      />
                    ) : (
                      <input
                        value={this.state.postulante.villa}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              villa: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square"
                        type="text"
                        placeholder="Los Alpes"
                        style={{ textTransform: "uppercase" }}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-home"></i>
                    </span>
                  </div>
                  <div className="help is-danger">{this.state.villaError}</div>
                </div>
              </div>

              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Comuna</label>
                  {this.state.comunaError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              comuna: e.target.value === 'Seleccione' ? '' : e.target.value
                            },
                          })
                        }
                        value={this.state.postulante.comuna}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        {this.state.comuna.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              comuna: e.target.value === 'Seleccione' ? '' : e.target.value
                            },
                          })
                        }
                        value={this.state.postulante.comuna}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        {this.state.comuna.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">{this.state.comunaError}</div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Género</label>
                  {this.state.generoError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              genero: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.genero}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>Femenino</option>
                        <option>Masculino</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-venus-mars"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              genero: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.genero}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Femenino</option>
                        <option>Masculino</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-venus-mars"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">{this.state.generoError}</div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Nacionalidad</label>
                  {this.state.nacionalidadError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onBlur={(e) => {
                          this.esExtranjero();
                        }}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              nacionalidad: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.nacionalidad}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Chile</option>
                        <option>Haití</option>
                        <option>Bolivia</option>
                        <option>Brazil</option>
                        <option>Argentina</option>
                        <option>Colombia</option>
                        <option>Ecuador</option>
                        <option>Paraguay</option>
                        <option>Peru</option>
                        <option>Uruguay</option>
                        <option>Venezuela</option>
                        <option>Republica Dominicana</option>
                        <option>Otros</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-globe-americas"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onBlur={(e) => {
                          this.esExtranjero();
                        }}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              nacionalidad: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.nacionalidad}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Chile</option>
                        <option>Haití</option>
                        <option>Bolivia</option>
                        <option>Brazil</option>
                        <option>Argentina</option>
                        <option>Colombia</option>
                        <option>Ecuador</option>
                        <option>Paraguay</option>
                        <option>Peru</option>
                        <option>Uruguay</option>
                        <option>Venezuela</option>
                        <option>Republica Dominicana</option>
                        <option>Otros</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-globe-americas"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.nacionalidadError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Estado Civil</label>
                  {this.state.estado_civilError ? (
                    <div className="control has-icons-left select is-danger select is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              estado_civil: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.estado_civil}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>Soltero/a</option>
                        <option>Casado/a</option>
                        <option>Viudo/a</option>
                        <option>Divorciado/a</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-heart"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary select is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              estado_civil: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.estado_civil}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Soltero/a</option>
                        <option>Casado/a</option>
                        <option>Viudo/a</option>
                        <option>Divorciado/a</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-heart"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.estado_civilError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Email</label>
                  <div className="control has-icons-left ">
                    {this.state.emailError ? (
                      <input
                        value={this.state.postulante.email}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              email: e.target.value,
                            },
                          })
                        }
                        className="input is-danger is-square"
                        type="text"
                        placeholder="luis@gmail.com"
                        style={{ textTransform: "uppercase" }}
                      />
                    ) : (
                      <input
                        value={this.state.postulante.email}
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              email: e.target.value,
                            },
                          })
                        }
                        className="input is-primary is-square"
                        type="text"
                        placeholder="luis@gmail.com"
                        style={{ textTransform: "uppercase" }}
                      />
                    )}
                    <span className="icon is-small is-left">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <div className="help is-danger">{this.state.emailError}</div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Telefono</label>
                  <div className="field has-addons is-square">
                    <div className="control is-square">
                      <div className="button is-static is-square">56</div>
                    </div>
                    <div className="control is-expanded">
                      {this.state.telefonoError ? (
                        <input
                          type="text"
                          maxLength={9}
                          value={this.state.postulante.telefono}
                          onChange={(e) =>
                            this.setState({
                              postulante: {
                                ...postulante,
                                telefono: e.target.value,
                              },
                            })
                          }
                          className="input is-danger is-square"
                          placeholder="99585859"
                          style={{ textTransform: "uppercase" }}
                        />
                      ) : (
                        <input
                          type="text"
                          maxLength={9}
                          value={this.state.postulante.telefono}
                          onChange={(e) =>
                            this.setState({
                              postulante: {
                                ...postulante,
                                telefono: e.target.value,
                              },
                            })
                          }
                          className="input is-primary is-square"
                          placeholder="99585859"
                          style={{ textTransform: "uppercase" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="help is-danger">
                    {this.state.telefonoError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Contacto Emergencia</label>
                  <div className="field has-addons is-square">
                    <div className="control is-expanded">
                      {this.state.nombre_emergenciaError ? (
                        <input
                          type="text"
                          value={this.state.postulante.nombre_emergencia}
                          onChange={(e) =>
                            this.setState({
                              postulante: {
                                ...postulante,
                                nombre_emergencia: e.target.value,
                              },
                            })
                          }
                          className="input is-danger is-square"
                          placeholder="Juan perez"
                          style={{ textTransform: "uppercase" }}
                        />
                      ) : (
                        <input
                          type="text"
                          value={this.state.postulante.nombre_emergencia}
                          onChange={(e) =>
                            this.setState({
                              postulante: {
                                ...postulante,
                                nombre_emergencia: e.target.value,
                              },
                            })
                          }
                          className="input is-primary is-square"
                          placeholder="Juan Perez"
                          style={{ textTransform: "uppercase" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="help is-danger">
                    {this.state.nombre_emergenciaError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Teléfono Contacto Emergencia</label>
                  <div className="field has-addons is-square">
                    <div className="control is-square">
                      <div className="button is-static is-square">56</div>
                    </div>
                    <div className="control is-expanded">
                      {this.state.telefono_emergenciaError ? (
                        <input
                          type="text"
                          maxLength={9}
                          value={this.state.postulante.telefono_emergencia}
                          onChange={(e) =>
                            this.setState({
                              postulante: {
                                ...postulante,
                                telefono_emergencia: e.target.value,
                              },
                            })
                          }
                          className="input is-danger is-square"
                          placeholder="99585859"
                          style={{ textTransform: "uppercase" }}
                        />
                      ) : (
                        <input
                          type="text"
                          maxLength={9}
                          value={this.state.postulante.telefono_emergencia}
                          onChange={(e) =>
                            this.setState({
                              postulante: {
                                ...postulante,
                                telefono_emergencia: e.target.value,
                              },
                            })
                          }
                          className="input is-primary is-square"
                          placeholder="99585859"
                          style={{ textTransform: "uppercase" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="help is-danger">
                    {this.state.telefono_emergenciaError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Disponibilidad de Turno(s)</label>
                  {this.state.disponibilidadError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              disponibilidad: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.disponibilidad}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>Dia</option>
                        <option>Noche</option>
                        <option>Ambos</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-stopwatch"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              disponibilidad: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.disponibilidad}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Dia</option>
                        <option>Noche</option>
                        <option>Ambos</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-stopwatch"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.disponibilidadError}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Como se enteró del trabajo</label>
                  {this.state.tipo_inscripcionError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              tipo_inscripcion: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.tipo_inscripcion}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>Por LLAMADO TELEFÓNICO de la empresa</option>
                        <option>Por WHATSAPP que me enviaron</option>
                        <option>Por RRSS (facebook/Instagram)</option>
                        <option>Por un familiar, amigo, vecino</option>
                        <option>Por AVISO radial</option>
                        <option>Por FERIA laboral</option>
                        <option>Por AFICHE publicado en la calle</option>
                        <option>Por VOLANTE recibido en la calle</option>
                        <option>Otros motivos</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-rss-square"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              tipo_inscripcion: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.tipo_inscripcion}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Por LLAMADO TELEFÓNICO de la empresa</option>
                        <option>Por WHATSAPP que me enviaron</option>
                        <option>Por RRSS (facebook/Instagram)</option>
                        <option>Por un familiar, amigo, vecino</option>
                        <option>Por AVISO radial</option>
                        <option>Por FERIA laboral</option>
                        <option>Por AFICHE publicado en la calle</option>
                        <option>Por VOLANTE recibido en la calle</option>
                        <option>Otros motivos</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-rss-square"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.tipo_inscripcionError}
                  </div>
                </div>
              </div>

              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Sube tu curriculum (opcional)</label>
                  <div className="control has-icons-left  ">
                    <input
                      type="file"
                      name="file"
                      accept=".doc,.docx,.pdf"
                      onChange={(e) =>
                        this.setState({
                          postulante: {
                            ...postulante,
                            curriculum: e.target.files[0],
                          },
                        })
                      }
                      className="input is-primary is-square"
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-upload"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Labor a la que postula</label>
                  {this.state.laborError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              labor: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.labor}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option  disabled={true}>──────Cargos Planta Rancagua y Chimbarongo─────</option>
                        <option>ASISTENTE ADMINISTRATIVO</option>
                        <option>CAMARERO / LECTOR / VALIDADOR </option>
                        <option>CONDUCTOR PROFESIONAL (Licencia de Conducir A2).</option>
                        <option>CONTROL DE CALIDAD</option>
                        <option>DIGITADOR</option>
                        <option>EMBALADORA / SELLADORA</option>
                        <option>ENZUNCHADOR</option>
                        <option>MONITOR DE PREVENCIÓN</option>
                        <option>MOVILIZADOR DE TRANSPALETA</option>
                        <option>OPERADOR DE GRUA HORQUILLA</option>
                        <option>OPERADOR DE PALLETIZADOR AUTOMÁTICO</option>
                        <option>OPERADOR DE UNITEC</option>
                        <option>OPERARIO DE ASEO / SERVICIOS GENERALES</option>
                        <option>OPERARIO DE BODEGA / PATIO</option>
                        <option>OPERARIO DE CENTRO DE ARMADO Y ALTILLO</option>
                        <option>OPERARIO DE FRIO / DESPACHO</option>
                        <option>OPERARIO DE LAVADO DE BANDEJAS / TOTES</option>
                        <option>OPERARIO DE PACKING</option>
                        <option>PALLETIZADOR</option>
                        <option>SUPERVISOR/A</option>
                        <option  disabled={true}>──────Cargos Planta la Unión──────</option>
                        <option>CONTROL DE TEMPERATURA RECEPCIÓN</option>
                        <option>RECEPCIONISTA</option>
                        <option>DIGITADOR/A</option>
                        <option>OPERADOR/A DE GRÚA HORQUILLA</option>
                        <option>MOVILIZADOR</option>
                        <option>SUPERVISOR DE DESPACHO</option>
                        <option>CAMARERO</option>
                        <option>OPERARIO DE DESPACHO</option>
                        <option>CONTROL Tº Y TARJAS</option>
                        <option>OPERADOR SADEMA</option>
                        <option>ENCARGADO DE ACOPIO</option>
                        <option>OPERARIO COSECHA</option>
                        <option>CONTROL DE PROCESOS COSECHA</option>
                        <option>SUPERVISOR/A DE CUADRILLA</option>
                        <option>OPERACIONES - LOGISTICA</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-briefcase"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              labor: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.labor}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option  disabled={true}>──────Cargos Planta Rancagua y Chimbarongo─────</option>
                        <option>ASISTENTE ADMINISTRATIVO</option>
                        <option>CAMARERO / LECTOR / VALIDADOR </option>
                        <option>CONDUCTOR PROFESIONAL (Licencia de Conducir A2).</option>
                        <option>CONTROL DE CALIDAD</option>
                        <option>DIGITADOR</option>
                        <option>EMBALADORA / SELLADORA</option>
                        <option>ENZUNCHADOR</option>
                        <option>MONITOR DE PREVENCIÓN</option>
                        <option>MOVILIZADOR DE TRANSPALETA</option>
                        <option>OPERADOR DE GRUA HORQUILLA</option>
                        <option>OPERADOR DE PALLETIZADOR AUTOMÁTICO</option>
                        <option>OPERADOR DE UNITEC</option>
                        <option>OPERARIO DE ASEO / SERVICIOS GENERALES</option>
                        <option>OPERARIO DE BODEGA / PATIO</option>
                        <option>OPERARIO DE CENTRO DE ARMADO Y ALTILLO</option>
                        <option>OPERARIO DE FRIO / DESPACHO</option>
                        <option>OPERARIO DE LAVADO DE BANDEJAS / TOTES</option>
                        <option>OPERARIO DE PACKING</option>
                        <option>PALLETIZADOR</option>
                        <option>SUPERVISOR/A</option>
                        <option 
                        disabled={true}>──────Cargos Planta la Unión──────</option>
                        <option>CONTROL DE TEMPERATURA RECEPCIÓN</option>
                        <option>RECEPCIONISTA</option>
                        <option>DIGITADOR/A</option>
                        <option>OPERADOR/A DE GRÚA HORQUILLA</option>
                        <option>MOVILIZADOR</option>
                        <option>SUPERVISOR DE DESPACHO</option>
                        <option>CAMARERO</option>
                        <option>OPERARIO DE DESPACHO</option>
                        <option>CONTROL Tº Y TARJAS</option>
                        <option>OPERADOR SADEMA</option>
                        <option>ENCARGADO DE ACOPIO</option>
                        <option>OPERARIO COSECHA</option>
                        <option>CONTROL DE PROCESOS COSECHA</option>
                        <option>SUPERVISOR/A DE CUADRILLA</option>
                        <option>OPERACIONES - LOGISTICA</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-briefcase"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">{this.state.laborError}</div>
                </div>
              </div>

              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Situación Migratoria</label>
                  {this.state.situacion_migratoriaError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        disabled
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              situacion_migratoria: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.situacion_migratoria}
                        className="selecetext select is-danger"
                        id="situacion_migratoria"
                      >
                        <option>Seleccione</option>
                        <option>Permanencia definitiva</option>
                        <option>Visa de permanencia temporaria</option>
                        <option>En tramite por renovación</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-venus-mars"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        disabled
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              situacion_migratoria: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.situacion_migratoria}
                        className="selecetext select is-primary "
                        id="situacion_migratoria"
                      >
                        <option>Seleccione</option>
                        <option>Permanencia definitiva</option>
                        <option>Visa de permanencia temporaria</option>
                        <option>En tramite por renovación</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-school"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.situacion_migratoriaError}
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Tipo Trabajador/ar</label>
                  {this.state.tipo_trabajadorError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              tipo_trabajador: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.tipo_trabajador}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>Nuevo/a</option>
                        <option>Antiguo/a</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-info-circle"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              tipo_trabajador: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.tipo_trabajador}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Nuevo/a</option>
                        <option>Antiguo/a</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-info-circle"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.tipo_trabajadorError}
                  </div>
                </div>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Nivel Educacional</label>
                  {this.state.nivel_educacionalError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              nivel_educacional: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.nivel_educacional}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>Universitaria</option>
                        <option>Técnica</option>
                        <option>Media</option>
                        <option>Básica</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-venus-mars"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              nivel_educacional: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.nivel_educacional}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>Universitaria</option>
                        <option>Técnica</option>
                        <option>Media</option>
                        <option>Básica</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-school"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.nivel_educacionalError}
                  </div>
                </div>
              </div>

              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Talla de ropa</label>
                  {this.state.talla_ropaError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              talla_ropa: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.talla_ropa}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>S</option>
                        <option>M</option>
                        <option>L</option>
                        <option>XL</option>
                        <option>XXL</option>
                        <option>XXXL</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-venus-mars"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              talla_ropa: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.talla_ropa}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>S</option>
                        <option>M</option>
                        <option>L</option>
                        <option>XL</option>
                        <option>XXL</option>
                        <option>XXXL</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-ruler-combined"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.talla_ropaError}
                  </div>
                </div>
              </div>

              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">N° Calzado</label>
                  {this.state.numero_calzadoError ? (
                    <div className="control has-icons-left select is-danger is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              numero_calzado: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.numero_calzado}
                        className="selecetext select is-danger"
                      >
                        <option>Seleccione</option>
                        <option>35</option>
                        <option>36</option>
                        <option>37</option>
                        <option>38</option>
                        <option>39</option>
                        <option>40</option>
                        <option>41</option>
                        <option>42</option>
                        <option>43</option>
                        <option>44</option>
                        <option>45</option>
                        <option>46</option>
                        <option>47</option>
                        <option>48</option>
                        <option>49</option>
                        <option>50</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-venus-mars"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary is-fullwidth">
                      <select
                        onChange={(e) =>
                          this.setState({
                            postulante: {
                              ...postulante,
                              numero_calzado: e.target.value,
                            },
                          })
                        }
                        value={this.state.postulante.numero_calzado}
                        className="selecetext select is-primary "
                      >
                        <option>Seleccione</option>
                        <option>35</option>
                        <option>36</option>
                        <option>37</option>
                        <option>38</option>
                        <option>39</option>
                        <option>40</option>
                        <option>41</option>
                        <option>42</option>
                        <option>43</option>
                        <option>44</option>
                        <option>45</option>
                        <option>46</option>
                        <option>47</option>
                        <option>48</option>
                        <option>49</option>
                        <option>50</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-shoe-prints"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">
                    {this.state.numero_calzadoError}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="label">Otras Experiencias</label>
                  <div className="control ">
                    <textarea
                      onChange={(e) =>
                        this.setState({
                          postulante: {
                            ...postulante,
                            otras_experiencias: e.target.value,
                          },
                        })
                      }
                      value={this.state.postulante.otras_experiencias}
                      className="textarea has-fixed-size is-primary"
                      placeholder="Escribe acá tus experiencias..."
                      style={{ textTransform: "uppercase" }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="field is-horizontal">
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control pr-5">
                  <div className="notification">
                    <i>
                      "En Ranco Cherries estamos comprometidos con la Ley de Inclusión <strong>(Ley N°21.015 | Incentiva la inclusión de personas con discapacidad en el ámbito laboral)</strong>, 
                      por lo que te invitamos a informarnos si requieres algún ajuste para participar adecuadamente en nuestros procesos de selección."
                    </i>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="field is-horizontal has-text-left">
              <div className="field-body">
                <div className="field is-narrow">
                <label className="label">¿Requieres algún ajuste para participar adecuadamente en nuestros procesos de selección?</label>
                  {this.state.inclusionError ? (
                    <div className="control has-icons-left select is-danger">
                      <select
                        onChange={(e) => {
                          this.setState({
                            postulante: { ...postulante, inclusion: e.target.value === "Seleccione"? "": e.target.value},
                          })
                        }}
                        value={this.state.postulante.inclusion}
                        className="selecetext select is-danger is-normal"
                      >
                        <option>Seleccione</option>
                        <option>Si</option>
                        <option>No</option>
                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-user-circle"></i>
                      </span>
                    </div>
                  ) : (
                    <div className="control has-icons-left select is-primary">
                      <select
                        onChange={(e) => {
                          this.setState({
                            postulante: { ...postulante, inclusion: e.target.value === "Seleccione"? "": e.target.value},
                          })
                        }}
                        value={this.state.postulante.inclusion}
                        className="selecetext select is-primary is-normal"
                      >
                        <option>Seleccione</option>
                        <option>Si</option>
                        <option>No</option>

                      </select>
                      <span className="icon is-small is-left">
                        <i className="fas fa-user-circle"></i>
                      </span>
                    </div>
                  )}
                  <div className="help is-danger">{this.state.plantaError}</div>
                </div>
              </div>
            </div>

            <hr />

            <div className="field">
              <div className="columns">
                <div className="column">
                  <button
                    type="reset"
                    className="button is-link is-square  is-light text-is-left"
                  >
                    <span className="icon is-small has-icons-left">
                      <i className="fas fa-ban"></i>
                    </span>
                    Cancelar
                  </button>
                </div>
                <div className="column">
                  <button
                    type="submit"
                    className="button is-primary is-square text-is-left"
                  >
                    <span className="icon is-small has-icons-left">
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    Inscribirse
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <br />
      </div>
    );
  }
}

export default FormularioPostulanteNuevo;
