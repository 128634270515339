import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import FormularioRender from './componentes/FormularioRender';
import HomeRender from './componentes/HomeRender';

const Routes = () =>{
    return(
        <Switch>
            <Route exact path='/' component={HomeRender} />
            <Route  path='/postulante' component={FormularioRender} />
            <Redirect  to={{ pathname: '/'}} />
        </Switch>
    );
}

export default Routes