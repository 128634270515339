import React, {Component} from 'react'
import './components.css'
import { Link } from 'react-router-dom'

class NavBar extends Component{
    render(){
        return (
<div className="">
<nav className="navbar is-light" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
   
  <Link to='/' className="navbar-item">
    <img className=" is-square imagenRanco" alt="Imagen-ranco" src="logoweb.png"/>
  </Link>

  </div>

  <div id="navbarBasicExample" className="navbar-menu">
    <div className="navbar-start is-spaced	">
      <Link to='/' className="navbar-item is-spaced	">
        Inicio
      </Link>
      <Link to='/postulante' className="navbar-item   is-spaced	">
        Inscribete Aqui !
      </Link>


    </div>
    <div className="navbar-end is spaced">
    <a className="navbar-item navbar-end" href="https://www.ranco.cl">
        Visita nuestra página web.
      </a>
    </div>
    </div>
</nav>

</div>
 )}
}

export default NavBar
